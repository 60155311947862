import '@assets/scss/pages/project.scss';

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// components
import Layout from '@src/layouts';
// import Video from '@components/atoms/Video';
import Blockquote from '@components/molecules/Blockquote';
// import ButtonExternal from '@components/atoms/buttons/ButtonExternal';
import Column from '@components/organisms/Column';
// import Cta from '@components/molecules/Cta';
import Gallery from '@components/organisms/Gallery';
import HeroProject from '@components/molecules/heros/HeroProject';
import Image from '@components/atoms/Image';
// import ItemArticle from '@components/molecules/items/ItemArticle';
import ListText from '@components/organisms/ListText';

// --------------------------------
// #region data
// --------------------------------

const language = 'en';

const pageClass = 'page-project';

const accent_color = '#EB553C';
const footerRelatedLinks = [
	{
		title: 'Terre des Hommes, My orange',
		url: '/en/projects/terre-des-hommes-my-orange/',
	},
];

// --------------------------------
// #endregion
// --------------------------------

const ProjectAckTypoPage = (props) => {
	const data = useStaticQuery(
		graphql`
			query {
				heroImage: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-cover.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				heroImageMobile: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-leschauffeurs-cover-mobile.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_1: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-brandDNA-EN.png"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1000, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_2: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-logo.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_3: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-arbres.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_4: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-pattern.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_5: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-carte-visite.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_6: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-en-tete.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_7: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-t-shirt.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_8: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-affiche.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_9: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-vehicule-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_10: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-vehicule-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_11: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-vehicule-3.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_12: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-chauffeur-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_13: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-chauffeur-2.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
				image_14: file(
					relativePath: {
						eq: "projects/chauffeurs-remy/img-siteweb-1.jpg"
					}
				) {
					childImageSharp {
						fluid(maxWidth: 1920, quality: 100) {
							...GatsbyImageSharpFluid_withWebp
						}
					}
				}
			}
		`
	);

	return (
		<Layout
			language={language}
			pageClass={pageClass}
			footerRelatedLinks={footerRelatedLinks}
			accentColor={accent_color}
			location={props.location}
			translations={[
				{
					uri: '/fr/projets/les-chauffeurs-de-remy/',
					lang: 'fr',
				},
			]}
			title="Les chauffeurs de Rémy - By Superhuit"
		>
			<HeroProject
				breadcrumb={{
					title: 'Work',
					link: '/en/projects/',
				}}
				title="Les chauffeurs de Rémy"
				image={{
					...data.heroImage.childImageSharp,
				}}
				imageMobile={{
					...data.heroImageMobile.childImageSharp,
				}}
			/>
			<section className="grid grid-content" aria-label="Project">
				<div className="project-intro" data-animation-page>
					<div className="project-summary text-content">
						<p className="p-medium">
							The journey is just as important as the destination.
						</p>

						<ListText
							className="element-spacing"
							title="Nos services"
							list={[
								'Brand positioning',
								'Naming',
								'Branding',
								'Visual identity',
							]}
						/>
					</div>
					<div className="project-description text-content">
						<h3>The challenge</h3>
						<p>
							Remy Brand, a transport company for disabled people,
							was about to enter a phase of development marked by
							a significant increase in the size of the company
							and therefore the number of employees and vehicles.
							This strategic step brought with it issues of brand
							recognition, market differentiation, and employee
							loyalty.
						</p>

						<h3>Our response </h3>
						<p>
							Our branding work identified the uniqueness of Remy
							Brand's character that sets the company apart from
							the rest of the market. Our creative process
							centered on the idea of translating this "soul" into
							a brand that is both simple and strong: Les
							Chauffeurs de Remy.
						</p>
						<p>
							A projection and risk-mapping exercise allowed us to
							identify the challenges and opportunities involved
							in the company's development in order to reduce the
							risks of losing a sense of belonging as the company
							evolves.
						</p>
					</div>
				</div>

				<div className="box" data-animation-page>
					<div className="list-two-columns align-items-center justify-normal">
						<div className="text-content">
							<h3>Brand DNA</h3>
							<p>
								Identify what makes them unique to propose a
								strong, coherent brand with significant growth
								potential.
							</p>
						</div>
						<Image
							{...data.image_1.childImageSharp}
							alt="Brand DNA"
						/>
					</div>
				</div>

				<Column modifiers="right">
					<div className="column__text text-content">
						<h3>Naming and logo</h3>
						<p>
							The company focuses on people, on life stories
							rather than on the means (of transport).
						</p>
						<p>
							So the soul of the company is not the means–or
							vehicles–but the people. It is these drivers who
							have a name, who form the backbone of the
							relationship to the service. Just like Remy would.
							Based on this observation, the name seemed obvious
							to us.
						</p>
					</div>

					<div className="column__media text-content">
						<Image {...data.image_2.childImageSharp} alt="Logo" />
					</div>
				</Column>

				<Column modifiers="right" className="grid-spacing">
					<div className="column__text text-content">
						<h3>From the tree to the forest</h3>
						<p>
							The symbol of the Rémy Brand is the tree. This
							reference point is a symbol of confidence and quiet
							strength.
						</p>
						<p>
							While the founder, Rémy, represented the tree, from
							now on Les Chauffeurs become the forest. Each one
							has its own shape and its own characteristics that,
							when combined, form a coherent whole.
						</p>
					</div>

					<div className="column__media text-content">
						<Image {...data.image_3.childImageSharp} alt="Trees" />
					</div>
				</Column>

				<Column modifiers="right" className="grid-spacing">
					<div className="column__text text-content">
						<h3>A forest in motion</h3>
						<p>
							And this forest is in motion, heading to your home
							to take you to your destination. It is a forest that
							is visible without being overbearing.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_4.childImageSharp}
							alt="Pattern"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_5.childImageSharp}
					alt="Business cards"
				/>
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_6.childImageSharp} alt="Letterhead" />
					<Image {...data.image_7.childImageSharp} alt="T-shirt" />
				</Gallery>
				<Image
					className="col-full grid-spacing"
					{...data.image_8.childImageSharp}
					alt="Poster"
				/>

				<Blockquote
					modifiers="big"
					uptitle=""
					quote="A forest in motion is a simple and strong sign of differentiation. A story that tells itself."
					more="Each vehicle is marked with the brand’s identity in a way that respects the integrity of the vehicle and avoids being overbearing. And so the vehicles become the brand's ambassadors: a strong sign of the company's regional presence."
					cite=""
				/>

				<Image
					className="col-full"
					{...data.image_9.childImageSharp}
					alt="Vehicle"
				/>
				<Gallery className="col-full grid-spacing" modifiers="gutter">
					<Image {...data.image_10.childImageSharp} alt="Vehicle" />
					<Image {...data.image_11.childImageSharp} alt="Vehicle" />
				</Gallery>

				<Column modifiers="right">
					<div className="column__text column__sticky text-content">
						<h3>A journey is above all a human encounter</h3>
						<p>
							Create an authentic relationship by approaching
							others with humanity and humility. In this way, the
							brand also tells the story of the drivers–Kilian,
							Angela, Roberto, Maria–the humans behind the
							service.
						</p>
						<p>
							It is this authentic and warm humanity that we
							wanted to transmit in the photo shoot for the visual
							identity.
						</p>
					</div>

					<div className="column__media text-content">
						<Image
							{...data.image_12.childImageSharp}
							alt="Driver"
						/>
						<Image
							{...data.image_13.childImageSharp}
							alt="Drivers"
							className="grid-spacing"
						/>
					</div>
				</Column>

				<Image
					className="col-full"
					{...data.image_14.childImageSharp}
					alt="Website"
				/>
			</section>
		</Layout>
	);
};

export default ProjectAckTypoPage;
